import React from "react";
import BaseInnerSubtitle from "../../sisu/title/BaseInnerSubtitle.sisu";

const NeoInnerSubtitle = (props) => {
  return (<BaseInnerSubtitle custom="p-col  subtitulo-interno" {...props}>
    {props.children}
  </BaseInnerSubtitle>);
};

export default NeoInnerSubtitle;
