import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import NeoGridContainer from "../../../design/design_components/neo/layout/NeoGridContainer.base";
import Icono from "../../../design/assets/img/wfi/icons/icon-ad.svg";
import QuestionMarkIcon from "../../../design/assets/img/wfi/icons/icon-question-mark.svg";
import NeoCard from "../../../design/design_components/neo/panel/NeoCard.base";
import NeoColumn from "../../../design/design_components/neo/layout/NeoColumn.base";
import NeoInputText from "../../../design/design_components/neo/form/NeoInputText.base";
import NeoButtonMain from "../../../design/design_components/neo/button/NeoButtonMain.base";
import useToastContext from "../../../hooks/useToastContext.hook";
import { CONST } from "../../../consts/consts";
import NeoTitleSecond from "../../../design/design_components/neo/title/NeoTitleSecond.base";
import WarningIcon from "../../../design/assets/img/wfi/icons/icon-warning.svg";
import NeoInputSwitch from "../../../design/design_components/neo/form/NeoInputSwitch.base";
import NeoTooltip_icon from "../../../design/design_components/neo/overlay/NeoTooltip_icon.base";
import NeoCalendar from "../../../design/design_components/neo/form/NeoCalendar.base";
import NeoMultiSelect from "../../../design/design_components/neo/form/NeoMultiSelect.base";
import MediaStoreSwapper from "./MediaStoreSwapper.component";
import AdContentHelpModal from "./AdContentHelpModal.component";
import Routes from "../../../routers/Routes.router.json";
import * as MediaTypes from "../../../models/media.model";
import {
  parseFileError,
  validateCreateMedia,
  validateDesktopFile,
  validateMobileFile,
  validateClickUrl,
  parseErrors,
} from "../../../validation/media/create-media.validation";
import AdContentManagerc from "./AdContentManagerc.component";
import useUploadMedia from "../../../hooks/useUploadMedia.hook";
import {
  getDefaultAd,
  getMediaDataInfo,
  postUploadNewImageMedia,
} from "../../../service/Media.service";
import {
  getCampaignDataInfo
} from "../../../service/Campaign.service";
import {
  getAllAudiences,
  getAudienceContacts
} from "../../../service/Audience.service";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { DateTime } from "luxon";
import UpdateAdButton from "./UpdateAdButton.component";
import NeoTooltip from "../../../design/design_components/neo/overlay/NeoTooltip.base";
import PauseAdButton from "./PauseAdButton.component";

import NeoTabView from "../../../design/design_components/neo/panel/NeoTabView.base";
import NeoTabPanel from "../../../design/design_components/neo/panel/NeoTabPanel.base";
import NeoCheckbox from "../../../design/design_components/neo/form/NeoCheckbox.base";
import { file } from "jszip";


const initialAudiences = [];
/**
 * @type {MediaTypes.MediaModel}
 */
const mediaDefaults = {
  id: undefined,
  name: "",
  isDefault: false,
  startDate: "",
  endDate: "",
  samePeriodRelatedCampaign: false,
  fileDesktop: undefined,
  fileAndroid: undefined,
  fileIos: undefined,
  clickUrl: "",
  fileType: "",
  stores: [],
  audiences: [],
};

export default function UpdateAdCampaign() {
  const toast = useToastContext();
  const history = useHistory();

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [checked, setChecked] = useState(true); // Estado para el checkbox del adcontentmanager

  const [media, setMedia] = useState(mediaDefaults);
  const [campaign, setCampaign] = useState();
  const [mediaErrors, setMediaErrors] = useState({});
  const [helpModalOpen, setHelpModalOpen] = useState(false);
  const [defaultAdId, setDefaultAdId] = useState(null);
  const [defaultAdModalOpen, setDefaultAdModalOpen] = useState(null);

  const { mediaId } = useParams();
  const isUpdateMode = !!mediaId;
  const { campaignId, campaignIdCons } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const { uploadImage, updateImage } = useUploadMedia();

  const showPauseButton = isUpdateMode && !media.isDefault;

  const [selectedMediaMobileDevice, setSelectedMediaMobileDevice] = useState("ios");

  const iosImageUploaded = media.fileIos || media.fileIosUrl;
  const iosImageRef = useRef();

  const [iosImageLoading, setIosImageLoading] = useState(false);

  const [fileIos, setFileIos] = useState(null);
  const [fileIosUrl, setFileIosUrl] = useState(null);

  const [audiences, setAudiences] = useState(initialAudiences);
  const [audiencesbyuser, setAudiencesByUser] = useState([]);

  const [audience, setAudience] = useState([]);

  const [totalContacts, setTotalContacts] = useState(0);

  const handleIosImageUpload = (event) => {
    const file = event.files[0];

    if (file) {
      setIosImageLoading(true);

      validateMobileFile(file)
        .then(async (error) => {
          if (error.length) {
            const newError = parseFileError(error);
            console.log(newError);

            setIosImageLoading(false);

            setMediaErrors({
              ...mediaErrors,
              fileIos: newError,
            });

            return;
          }

          setMediaErrors({
            ...mediaErrors,
            fileIos: undefined,
          });

          try {
            //onUpdateFileIos(file);
            updateMedia({ fileIos: file })
            await updatePreview(file, iosImageRef);
          } catch (error) {
            console.log(error, "error on update preview");
          } finally {
            setIosImageLoading(false);
          }
        })
        .catch((error) => {
          setIosImageLoading(false);
          console.log(error, "error on validate file");
        });
    }

    event.options.clear();
  };

  async function updatePreview(file, ref) {

    return new Promise((resolve, reject) => {
      try {
        let reader = new FileReader();
        reader.readAsDataURL(file);
     
        reader.onload = function () {
     
          ref.current.src = reader.result;
          setFileIosUrl(reader.result);
          //setFileIosUrl(reader.result); 
     
          //const storedFileIosUrl = sessionStorage.getItem("fileIosUrl");
          resolve();
        };
      } catch (error) {
        reject(error);
      }
    });
  }
  useEffect(() => {
    
    if (fileIosUrl && iosImageRef.current) {
      iosImageRef.current.src = fileIosUrl;
    }
  }, [fileIosUrl]);

  useEffect(() => {
    //selectedDeviceRef.current = selectedMediaMobileDevice; // Sincroniza el ref
    
  }, [selectedMediaMobileDevice]);

  useEffect(() => {
    let mounted = true;

    if (isUpdateMode && mounted) {
      fetchAudiences();
      fetchAd();
     
    }

    if (mounted) {
      gettingCampaignData();
      fetchDefaultAd();
    }

    /*if (mounted) {
      fetchDefaultAd();
    }*/

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (media.startDate) {
      validateOnly("startDate");
    }
  }, [media.startDate]);

  useEffect(() => {
    if (media.endDate) {
      validateOnly("endDate");
    }
  }, [media.endDate]);

  useEffect(() => {
    if (campaign) {
      
    }
  }, [campaign]);

  const titleChoose = () => {
    if (!isUpdateMode) {
      return "Creación de anuncio";
    } else {
      return "Edición de anuncio";
    }
  };

  // Cambio en multiselect de Audiencias
const handleAudienceChange = useCallback(async (event) => {
  
  // Obtener los IDs de las audiencias seleccionadas
  const selectedAudiences = event.value;
 
  setAudience(selectedAudiences);
 /* await updateMedia({
    // Mantener el resto de las propiedades de media
    audiences:audience

  });*/
  /*updateMedia((prevMedia) => {
    const updatedMedia = {
      ...prevMedia,
      audiences: selectedAudiences
    };
   
    return updatedMedia;
  });*/



  // Calcular el total de contactos que tendrán las audiencias seleccionadas
  setAudienceContacts(selectedAudiences);

  
 
}, []);





function setAudienceContacts(selectedAudiences) {
  setIsLoading(true); // Comienza a cargar
  let totalContacts = 0;

  const promises = selectedAudiences.map(audienceId => {
      return getAudienceContacts(audienceId)
          .then(contacts => {
              totalContacts += contacts.payload.count; // Sumar el número de contactos
          })
          .catch(error => {
              console.log(error);
          });
  });

  Promise.all(promises)
      .then(() => {
          
          setTotalContacts(totalContacts); // Actualiza el estado
      })
      .finally(() => {
          setIsLoading(false); // Termina la carga
      });
}

function updateAudienceContacts(audienceId, contacts) {
  // @ts-expect-error
  setAudiences((prev) => {
      const newAudiences = prev.map(audience => {
          if (audience.ID === audienceId) {
              return { ...audience, CONTACTS: contacts };
          }

          return audience;
      });

      return newAudiences;
  });
}

  async function fetchAudiences() {
    setIsLoading(true);

    const audiences = await getAllAudiences();
    

    const transformedAudiences = audiences.payload.map(audience => ({
      label: audience.NAME,
      value: audience.ID
    }));

    // Actualizar el estado con el arreglo transformado
    setAudiencesByUser(transformedAudiences);

    //setAudienceContacts(audiences.payload);

    setIsLoading(false);
}


  /**
   * Updates the media object with the new properties.
   * Just a shorthand for the setMedia function.
   * @param {Partial<MediaTypes.MediaModel>} newMediaProperties
   */
  const updateMedia = async (newMediaProperties) => {
    setMedia((prevMedia) => ({ ...prevMedia, ...newMediaProperties }));
    const updatedMedia = { ...media, ...newMediaProperties };

  };

  const updateCampaign = async (newCampaignProperties) => {
    setCampaign((prevCampaign) => ({ ...prevCampaign, ...newCampaignProperties }));
 
    const updatedCampaign = { ...campaign, ...newCampaignProperties };
  };

  // Obtener la información de la campaña
  const gettingCampaignData = async () => {
    
    let response = await getCampaignDataInfo(campaignId);
    
    if (!response.success) {
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
      );

    } else {
      const campaign = response.result;
      
      await updateCampaign({
        id: campaignId,
        name: campaign.NAME,
        isDefault: Boolean(campaign.IS_DEFAULT),
        startDate: parseDateFromServer(campaign.START_DATE),
        endDate: parseDateFromServer(campaign.END_DATE),
        status: campaign.STATUS_ID,
        statusName: campaign.STATUS_NAME,
        isDistributed: Boolean(campaign.IS_DISTRIBUTED),
      });
      updateMedia({ campaignId: campaignId });

  
    }
  };


  const handleFetchMediaStoresError = (error) => {
    toast.setMessage(
      CONST.SEVERITY.ERROR,
      CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
      CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
    );
  };

  useEffect(() => {
  
    updateMedia({ audiences: audience });
  }, [audience]);

  async function fetchAd() {
    let response = await getMediaDataInfo(mediaId);

    if (!response.success) {
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
      );
    } else {
      const media = response.result;
     
      // Cargarlas en el multiselect 
   
     // const audienceIds = media.audiences.map((audience) => audience.AUDIENCE_ID);
      const audienceIds = media.audiences
  ? media.audiences.map(audienceres => audienceres.AUDIENCE_ID)
  : []; 
      
      const validAudienceIds = audienceIds.map(id => Number(id));
      setAudience(validAudienceIds);
   
   
      // Actualizar conteo de contactos
      setAudienceContacts(audienceIds);
      
      await updateMedia({
        id: mediaId,
        name: media.NAME,
        isDefault: false,
        startDate: parseDateFromServer(media.START_DATE),
        endDate: parseDateFromServer(media.END_DATE),
        samePeriodRelatedCampaign: Boolean(media.SAME_PERIOD_RELATED_CAMPAIGN),
        clickUrl: media.CLICK_URL,
        fileDesktopUrl: media.URL,
        fileAndroidUrl: media.URL_ANDROID,
        fileIosUrl: media.URL_IOS,
        fileType: media.FILE_TYPE,
        status: media.STATUS,
        audiences: audienceIds,
      });

          
      
    }
  }

  async function fetchDefaultAd() {
    let response = await getDefaultAd();
    
    if (!response.success) {
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
      );
    } else {
      const defaultAd = response.result;

      if (defaultAd?.ID) {
      
        setDefaultAdId(defaultAd.ID);
        await updateMedia({ isDefault: false });
      }
      else {
     
        await updateMedia({ isDefault: false });
      }
    }
  }

  /**
   * @param {keyof MediaTypes.MediaModel} field
   */
  async function validateOnly(field) {
    const errors = await validateCreateMedia(media, campaign);

    setMediaErrors({
      ...mediaErrors,
      [field]: errors[field],
    });
  }

  const submitAd = async () => {
     setIsLoading(true);
    
     
     await updateMedia({
      // Mantener el resto de las propiedades de media
      audiences:audience

    });
   
    if (campaign?.isDistributed) {
     
      updateMedia({
        ...media,  // Mantener el resto de las propiedades de media
        endDate: campaign.endDate,
        startDate: campaign.startDate

      });
    }
    const errors = await validateCreateMedia(media, campaign);
    
    setMediaErrors({
      ...errors,
      fileIos: !media.fileIosUrl
        ? parseFileError(await validateMobileFile(media.fileIos))
        : undefined,
      fileAndroid: !media.fileAndroidUrl
        ? parseFileError(
          (await validateMobileFile(media.fileAndroid)).filter(
            (err) => err !== "required"
          )
        )
        : undefined,
      fileDesktop: !media.fileDesktopUrl
        ? parseFileError(await validateDesktopFile(media.fileDesktop))
        : undefined,
    });

    if (Object.keys(errors).length > 0) {
      setIsLoading(false);
      return;
    }

    try {
      const mediaToUpload = await prepareMediaToUpload(media);

      const response = await postUploadNewImageMedia({ media: mediaToUpload });



      if (response.success) {
        toast.setMessage(
          CONST.SEVERITY.SUCCESS,
          CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.HEADLINE,
          CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.DETAILS
        );
        history.push(`/campanias/view/${campaignId}/${campaignIdCons}`);
      } else {
        toast.setMessage(
          CONST.SEVERITY.ERROR,
          CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
          CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
        );
      }
    } catch (error) {
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        CONST.TOAST_MESSAGES.FAIL_TO_CREATE.HEADLINE,
        CONST.TOAST_MESSAGES.FAIL_TO_CREATE.DETAILS
      );

      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Receives a date string from the server and returns a Date object.
   * Since the server returns a date in UTC because of javascript's Date object
   * parsing. As we only store the date in the database, if we have the value '2024-08-24'
   * it will return '2024-08-24T00:00:00.000Z' which is the same date but with the timezone
   * set to UTC. So if we parse this to UTC-6 to a JS Date it will show '2024-08-23T18:00:00.000Z'
   * and thats the date the Calendar component will show, which is incorrect.
   * @param {string} date
   * @returns
   */
  const parseDateFromServer = (date) => {
    if (!date) return null;
    return DateTime.fromSQL(
      DateTime.fromISO(date).setZone("UTC").toSQLDate()
    ).toJSDate();
  };

  const prepareMediaToUpload = async (media) => {
    const { desktopUrl, androidUrl, iosUrl } = await getMediaFileUrls(media);

    if (!desktopUrl || !iosUrl) {
      throw new Error("Error getting images urls");
    }

    const defaultMediaFileType = media.fileIos?.type?.includes("gif")
      ? "GIF"
      : "Imagen";

    const mediaFileType = media.fileType
      ? media.fileType
      : defaultMediaFileType;

    return {
      ...media,
      startDate: media.isDefault
        ? null
        : DateTime.fromJSDate(media.startDate).toSQLDate(),
      endDate: media.isDefault
        ? null
        : DateTime.fromJSDate(media.endDate).toSQLDate(),
      fileTypeId: mediaFileType,
      url: desktopUrl,
      urlAndroid: androidUrl,
      urlIos: iosUrl,
      stores: media.stores.map((store) => store.id),
    };
  };

  const getMediaFileUploadNeeded = (media) => {
    const promises = [];

    if (media.fileDesktop) {
      if (media.fileDesktopUrl) {
        promises.push(updateImage(media.fileDesktop, media.fileDesktopUrl));
      } else {
        promises.push(uploadImage(media.fileDesktop));
      }
    } else {
      promises.push(false);
    }

    if (media.fileAndroid) {
      if (media.fileAndroidUrl) {
        promises.push(updateImage(media.fileAndroid, media.fileAndroidUrl));
      } else {
        promises.push(uploadImage(media.fileAndroid));
      }
    } else {
      promises.push(false);
    }

    if (media.fileIos) {
      if (media.fileIosUrl) {
        promises.push(updateImage(media.fileIos, media.fileIosUrl));
      } else {
        promises.push(uploadImage(media.fileIos));
      }
    } else {
      promises.push(false);
    }

    return promises;
  };

  const getMediaFileUrls = async (media) => {
    const imagePromises = await Promise.allSettled(
      getMediaFileUploadNeeded(media)
    );

    let [fileDesktop, fileAndroid, fileIos] = imagePromises.map((promise) =>
      promise.status === "fulfilled" ? promise.value : null
    );

    if (!fileDesktop || !fileAndroid || !fileIos) {
      console.log("Error uploading images", imagePromises);
    }

    return {
      desktopUrl: fileDesktop ? fileDesktop.url : media.fileDesktopUrl,
      androidUrl: fileAndroid ? fileAndroid.url : media.fileAndroidUrl,
      iosUrl: fileIos ? fileIos.url : media.fileIosUrl,
    };
  };

  /**
   * @param {{ status: string}} param0
   */
  const handleUpdatedAd = async ({ status }) => {
    updateMedia({ status });

    toast.setMessage(
      CONST.SEVERITY.SUCCESS,
      "Estatus",
      "Se actualizó el estado del anuncio"
    );
  };

  const handleErrorUpdateAd = () => {
    toast.setMessage(
      CONST.SEVERITY.ERROR,
      CONST.TOAST_MESSAGES.FAIL_TO_UPLOAD.HEADLINE,
      CONST.TOAST_MESSAGES.FAIL_TO_UPLOAD.DETAILS
    );
  };

  // Función para obtener el color del círculo según el estatus
  const getStatusColor = (status) => {
    switch (status) {
      case "Activa":
        return "#44A143";
      case "Pausada":
        return "#FFC700";
      case "Finalizada":
        return "#6C757D";
      case "Programada":
        return "#F56B1D";
      case "Incompleta":
        return "#E04460";
      default:
        return "transparent"; // Por si acaso hay un estatus no reconocido
    }
  };

  const getTooltipContent = (statusName) => {
    switch (statusName) {
      case 'Activa':
        return 'Esta campaña está siendo mostrada actualmente.';
      case 'Incompleta':
        return 'Esta campaña no está siendo mostrada, debes crear mínimo un anuncio para que tu campaña empiece a funcionar.';
      case 'Pausada':
        return 'Esta campaña ha sido pausada, si quieres que vuelva a funcionar debes reactivarla.';
      case 'Finalizada':
        return 'Esta campaña ha finalizado su período de vigencia.';
      case 'Programada':
        return 'Esta campaña ha sido programada, se activará cuando comience su período de vigencia.';
      default:
        return 'Estado desconocido.';
    }
  };

  

  const handleNextTab = () => {
    if (activeTabIndex === 0) {
      updateMedia({
        ...media,  // Mantener el resto de las propiedades de media
        endDate: campaign.endDate,
        startDate: campaign.startDate
      });
      setActiveTabIndex(activeTabIndex + 1);
    } else if (activeTabIndex === 1) {
      // Estamos en el segundo tab
      // todo: activar esta línea cuando se implementen audiencias
       if (campaign?.isDistributed || campaign?.isDefault) { 
      //if (true) {
        // Si la campaña está distribuida, ejecutar la lógica de crear anuncio
        if (campaign?.isDistributed) {
          updateMedia({
            ...media,  // Mantener el resto de las propiedades de media
            endDate: campaign.endDate,
            startDate: campaign.startDate
          });
        }
        submitAd();
      } else {
        // Si no está distribuida, avanzar al tercer tab
        setActiveTabIndex(activeTabIndex + 1);
      }
    } else {
      // Estamos en el tercer tab, ejecutar submitAd para crear el anuncio
      submitAd(); // En el último tab, enviar el anuncio
    }
  };


  const onCheckSameValidity = (e) => {
    if (e.checked) {
     
      // Forzar las fechas de los calendarios
      /* updateMedia({ startDate: new Date('01/01/2024') });
       updateMedia({ endDate: new Date('31/12/2024') });*/
      // Forzar las fechas de los calendarios
      updateMedia({
        ...media,  // Mantener el resto de las propiedades de media
        endDate: campaign.endDate,
        startDate: campaign.startDate,
        samePeriodRelatedCampaign: true
      });
    } else {
      // Puedes dejar esto vacío o restablecer las fechas si el checkbox se desmarca
      updateMedia({
        startDate: null,
        endDate: null,
        samePeriodRelatedCampaign: false
      });
    }
  };

  const handleClickUrlBlur = (event) => {
    const error = parseErrors({
      clickUrl: validateClickUrl(event.target.value),
    });

    setMediaErrors({
      ...mediaErrors,
      clickUrl: error.clickUrl,
    });

  };


  return (
    <>
      <NeoGridContainer>

        <NeoTitleSecond
          col="8"
          title={titleChoose()}
          subtitle={isUpdateMode ? media.name : ""}
          icon={Icono}
          goBack={true}
        />
        {showPauseButton && (
          <NeoColumn col="4" extra="p-d-flex p-jc-end p-ai-center">
            <PauseAdButton
              adId={media.id}
              currentStatus={media.status}
              onUpdateStatusError={handleErrorUpdateAd}
              onUpdateStatusSuccess={handleUpdatedAd}
            />
          </NeoColumn>
        )}
      </NeoGridContainer>

      <NeoCard custom="custom-padding-card">

        <NeoColumn col="4">
          Campaña relacionada
        </NeoColumn>
        <NeoColumn col="4">
          Fechas de Vigencia
        </NeoColumn>
        <NeoColumn col="4">
          Tipo / Estatus
        </NeoColumn>

        <NeoColumn col="4">
          <strong># {campaignIdCons} - {campaign?.name}</strong>
        </NeoColumn>
        <NeoColumn col="4">
          {campaign?.startDate == null && campaign?.endDate == null ? (
            <span><strong>Permanente</strong></span>
          ) : (
            <span><strong>{campaign?.startDate?.toLocaleDateString()} - {campaign?.endDate?.toLocaleDateString()}</strong></span>
          )}
        </NeoColumn>
        <NeoColumn col="4">
          <div id="campaignInfo" style={{ display: "flex", alignItems: "center" }}>
            {campaign?.isDefault ? (
              <strong>Campaña Default</strong>
            ) : campaign?.isDistributed ? (
              <strong>Campaña Distribuida</strong>
            ) : (
              <strong>Campaña Regular</strong>
            )}
            <span
              style={{
                display: "inline-block",
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                backgroundColor: getStatusColor(campaign?.statusName),
                margin: "0 8px"
              }}
            ></span>
            <span>{campaign?.statusName}</span>
          </div>

          <NeoTooltip target="#campaignInfo" content={getTooltipContent(campaign?.statusName)} position="top" />
        </NeoColumn>

      </NeoCard>


      <NeoCard custom="custom-padding-card">
        <div className="custom-tab" style={{ width: "100%" }}>
          <NeoTabView
            panelContainerStyle={{ backgroundColor: "red", width: "100%" }}
            style={{ width: "100%" }}
            activeIndex={activeTabIndex}
            renderActiveOnly={false}
            //onTabChange={(e) => setActiveTabIndex(e.index)}
            onTabChange={(e) => {
              
              // Evita el cambio al tab "Audiencias" si está distribuido
              if (e.index === 2 && (campaign?.isDistributed || campaign?.isDefault)) {
                // No actualiza `activeTabIndex` para mantener el tab actual
                //setActiveTabIndex(1);

                return;
              }
              // Cambia el tab si no está bloqueado
              setActiveTabIndex(e.index);

              /*if (iosImageRef.current && fileIosUrl) {
               
                iosImageRef.current.src = fileIosUrl;
               
              }*/
            }}
          >
            <NeoTabPanel
              unstyled
              key="1"
              header="Configuración general"
              headerStyle={{ margin: 0, padding: 0 }}
              style={{ margin: 0, padding: "1rem 0" }} // Agrega un padding superior
            >
              {/* Nombre y Enlace del anuncio en el mismo renglón */}

              <div className="p-d-flex p-jc-between" style={{ marginTop: '1rem' }}>



                <NeoColumn col="6" extra="p-p-0">
                  <div className="p-d-flex p-ai-center pl-8 mb-4" >
                    <label className="input-label p-mr-1">Nombre</label>
                  </div>
                  <NeoInputText
                    extra="p-pt-1 p-pb-0 p-mt-0 p-mb-0"
                    value={media.name}
                    onChange={(event) => updateMedia({ name: event.target.value })}
                    onBlur={() => validateOnly("name")}
                    maxLength={120}
                    error={mediaErrors.name}

                  />
                </NeoColumn>

                <NeoColumn col="6" extra="p-p-0">
                  <div className="p-d-flex p-ai-center pl-8 mb-4">
                    <label className="input-label p-mr-1">Enlace del anuncio</label>
                    <NeoTooltip_icon
                      text="Destino al que se dirigirá el cliente al hacer clic en el anuncio."
                      extra="inline-tooltip"
                      position="bottom"
                    />
                  </div>
                  <NeoInputText
                    extra="p-p-0"
                    value={media.clickUrl}
                    placeholder="http://www.ejemplo.com/"
                    maxLength={255}
                    onChange={(event) =>
                      updateMedia({ clickUrl: event.target.value })
                    }
                    onBlur={handleClickUrlBlur}
                    error={mediaErrors.clickUrl}

                  />
                </NeoColumn>
              </div>

              {/* Nueva línea para Información general */}
              <div className="p-col-12 p-d-flex p-ai-center p-jc-between pb-16" style={{ marginTop: '2rem' }}>
                <h2 className="fs-22 bold text-primary p-m-0 p-p-0">
                  Periodo de vigencia
                </h2>
                {!campaign?.isDefault && !campaign?.isDistributed && (
                  <div className="p-ml-auto">
                    <NeoCheckbox
                      id="1"
                      value={media.samePeriodRelatedCampaign}
                      onChange={onCheckSameValidity}
                      label="Misma vigencia que campaña relacionada"
                    />
                  </div>
                )}
              </div>
              {campaign?.isDistributed && (
                <>
                  <NeoColumn col="7" extra="p-p-0">
                    <div className="p-d-flex p-ai-center pl-8 mb-4" style={{ paddingTop: '30px', fontSize: '17px' }}>


                      Limitada a la vigencia de la campaña
                      <NeoTooltip_icon
                        content="Estará limitado para asegurar que se realice una distribución equitativa de los anuncios."
                        extra="inline-tooltip"
                        position="right"
                      />




                    </div>
                  </NeoColumn>
                </>
              )}

              {!campaign?.isDistributed && (
                <>
                  <div className="p-d-flex p-jc-between" style={{ marginTop: '1rem' }}>

                    {campaign?.isDefault ? (
                      <div>

                        <div className="p-d-flex p-ai-center pl-8 mb-4">
                          <label className="input-label mr-4" style={{ fontSize: "16px" }}>Permanente</label>
                          <NeoTooltip_icon
                            content="La campaña default es permanente para asegurar que siempre cuentes con anuncios en tus splash pages."
                            extra="inline-tooltip"
                            position="right"
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="p-d-flex p-col p-p-0">
                        {/* Fecha de inicio */}
                        <NeoColumn col="6" extra="p-p-0">
                          <div className="p-d-flex p-ai-center pl-8 mb-4">
                            <label className="input-label mr-4">Fecha de inicio</label>
                            <NeoTooltip_icon
                              text="La fecha mostrada corresponde a un día completo."
                              extra="inline-tooltip"
                              position="bottom"

                            />
                          </div>
                          <NeoCalendar
                            extra="p-pt-0 p-mt-0 p-pb-0 p-mb-0"
                            value={media.startDate}
                            placeholder="dd/mm/yyyy"
                            dateFormat="dd/mm/yy"
                            onChange={(event) => updateMedia({ startDate: event.value })}
                            error={mediaErrors.startDate}
                            disabled={media.samePeriodRelatedCampaign}
                            onBlur={() => validateOnly("startDate")}
                          />
                        </NeoColumn>

                        {/* Fecha de finalización */}
                        <NeoColumn col="6" extra="p-p-0">
                          <div className="p-d-flex p-ai-center pl-8 mb-4">
                            <label className="input-label mr-4">Fecha de finalización</label>
                            <NeoTooltip_icon
                              text="La fecha mostrada corresponde a un día completo."
                              extra="inline-tooltip"
                              position="bottom"
                            />
                          </div>
                          <NeoCalendar
                            extra="p-pt-0 p-mt-0 p-pb-0 p-mb-0"
                            value={media.endDate}
                            placeholder="dd/mm/yyyy"
                            dateFormat="dd/mm/yy"
                            onChange={(event) => updateMedia({ endDate: event.value })}
                            error={mediaErrors.endDate}
                            disabled={media.samePeriodRelatedCampaign}
                            onBlur={() => validateOnly("endDate")}
                          />
                        </NeoColumn>
                      </div>
                    )}
                  </div>
                </>
              )}

            </NeoTabPanel>
            <NeoTabPanel
              unstyled
              key="2"
              header="Contenido multimedia"
              headerStyle={{ margin: 0, padding: 0 }}
              style={{ margin: 0, padding: "1rem 0" }}
            >

              <div className="p-col p-d-flex p-flex-column pb-0">


                <AdContentManagerc
                  media={media}
                  errors={mediaErrors}
                  onUpdateErrors={setMediaErrors}
                  onUpdateFileIos={(file) => updateMedia({ fileIos: file })}
                  onUpdateFileAndroid={(file) => updateMedia({ fileAndroid: file })}
                  onUpdateFileDesktop={(file) => updateMedia({ fileDesktop: file })}
                  onUpdateClickUrl={({ clickUrl }) => updateMedia({ clickUrl })}
                  selectedMediaMobileDevice={selectedMediaMobileDevice}
                  checked={checked}
                  setChecked={setChecked}
                  iosImageUploaded={iosImageUploaded}
                  iosImageRef={iosImageRef}
                  iosImageLoading={iosImageLoading}
                  handleIosImageUpload={handleIosImageUpload}
                />
              </div>



            </NeoTabPanel>
            
             <NeoTabPanel
              unstyled
              key="3"
              header={
                <div style={{ display: 'flex', alignItems: 'center', opacity: campaign?.isDistributed || campaign?.isDefault ? 0.5 : 1, cursor: campaign?.isDistributed || campaign?.isDefault ? 'not-allowed' : 'pointer' }}>
                  <span style={{ pointerEvents: 'none' }}>Audiencias</span>
                  {(campaign?.isDistributed || campaign?.isDefault) && (
                    <NeoTooltip_icon
                      text="Las audiencias solo están disponibles para campañas regulares."
                      extra="inline-tooltip"
                      position="bottom"
                      style={{ marginLeft: '5px', cursor: 'pointer' }}
                    />
                  )}
                </div>
              }
              headerStyle={{ margin: 0, padding: 0 }}
              style={{ margin: 0, padding: 0 }}
            >
              <div className="card-style" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', padding: '40px 16px 16px 16px' }}> 
            <div style={{ flex: '1' }}>
              {/* Contenedor para el label encima del control */}
              <div className="p-d-flex p-flex-column">
                <label className="input-label" style={{ marginBottom: '-10px', paddingLeft: '10px' }}>Audiencia</label> 
                <NeoMultiSelect
                  options={audiencesbyuser}
                  value={audience}
                  placeholder="Todos los contactos"
                  selectedItemsLabel="{0} audiencias"
                 
                  className="custom-multi-select-filter"
                  onChange={handleAudienceChange}
                  filter
                  name="audience"
                />
              </div>
            </div>

            {/* Contenedor derecho con texto y número alineados correctamente */}
            <div style={{ flex: '1', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', marginTop: '-15px', paddingLeft:'20px' }}> 
              <p style={{ marginBottom: '4px' }}>Total de contactos incluidos  <NeoTooltip_icon
                      text="Suma total de contactos únicos que cumplen con los criterios de la(s) audiencia(s), puede variar según su comportamiento durante el anuncio."
                      extra="inline-tooltip"
                      position="bottom"
                      style={{ marginLeft: '5px', cursor: 'pointer' }}
                    /></p>

                    {isLoading ? (
                      <div className="spinner"> {/* Puedes reemplazar esto con el componente de spinner que prefieras */}
                          <p>Cargando contactos...</p>
                          <div className="loader"></div> {/* Ejemplo de un spinner */}
                      </div>
                  ) : (
                      <p style={{ fontSize: '1.5rem', fontWeight: 'bold', marginTop: '0' }}>
                          {totalContacts} {/* Muestra el total de contactos */}
                      </p>
                  )}
              
            </div>
          </div>

            </NeoTabPanel>

          </NeoTabView>
        </div>

      </NeoCard>

      <div className="p-d-flex p-jc-end pb-10 gap-16">
        {isUpdateMode && (
          <>
            <NeoButtonMain
              alternative="outlined"
              extra="action-button bg-white"
              onClick={() => history.push(`/campanias/view/${campaignId}/${campaignIdCons}`)}
            >
              Cancelar
            </NeoButtonMain>
            <UpdateAdButton
              showSpinner={isLoading}
              onUpdateAd={submitAd}
            ></UpdateAdButton>
          </>
        )}

        {!isUpdateMode && (
          <div className="p-d-flex p-jc-between">
            <NeoButtonMain
              alternative="outlined"

              onClick={() => history.push(`/campanias/view/${campaignId}/${campaignIdCons}`)}
              style={{ marginRight: "1.5rem", padding: "0.5rem 1rem", fontSize: "14px" }}
            >
              Cancelar
            </NeoButtonMain>
            <NeoButtonMain onClick={handleNextTab} disabled={isLoading}>
              {activeTabIndex === 0 ? ( // En el primer tab
                <>
                  {isLoading && <i className="pi pi-spin pi-spinner mr-5"></i>}
                  Siguiente
                </>
              ) : activeTabIndex === 1 ? ( // En el segundo tab
                <>
                  {isLoading && <i className="pi pi-spin pi-spinner mr-5"></i>}
                   {campaign?.isDistributed ? "Crear anuncio" : "Siguiente"} 
                 
                </>
              ) : (
                "Crear anuncio"
              )}
            </NeoButtonMain>
          </div>
        )}
      </div>

      <AdContentHelpModal
        open={helpModalOpen}
        onClose={() => setHelpModalOpen(false)}
      />


    </>
  );
}
